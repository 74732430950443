@media (max-width: 767px) {
  .site-utils__item.site-utils__item--email {
    position: absolute;
    top: 13px;
  }
}

.site-utils__item.site-utils__item--email {
  right: 6px !important;
}

.js-sticky-header .js-site-header-sticky .site-utils__item--email {
  display: inline-block;
  top: 0;
}

.about-bobbi--desktop {
  display: block;
}

.about-bobbi__story {
  padding-top: 45px;
}

.about-bobbi__story-bobbi__image {
  width: 36%;
  display: block;
  float: left;
  margin-right: 25px;
  margin-bottom: 10px;
}

.about-bobbi__story-bobbi__prologue {
  display: block;
  line-height: 1.5em;
  font-size: 14px;
}

.about-bobbi__story-bobbi__quote {
  font-size: 30px;
  margin-top: 75px;
  clear: both;
}

.about-bobbi__story-bobbi__quote-image {
  margin-bottom: 50px;
}

.about-bobbi__story-chapters__number {
  font-size: 24px !important;
  display: block;
}

.about-bobbi__story-chapters__name {
  font-size: 20px !important;
  line-height: 1.5em;
  padding-left: 11px;
  display: block;
}

.about-bobbi__story-chapters .icon-arrow_right {
  font-size: 36px;
}

.about-bobbi__story-chapter-one__photos {
  display: block;
  float: right;
  width: 33%;
  margin-left: 29px;
  margin-right: 30px;
  margin-bottom: 15px;
}

.about-bobbi__story-chapter-one__image {
  display: block;
  margin-bottom: 20px;
}

.about-bobbi__story-chapters__header {
  padding: 10px 0;
}

.about-bobbi__story-bobbi__header {
  display: block;
}

.about-bobbi__story-chapter-two__photos {
  display: block;
  float: left;
  width: 40%;
  max-width: 390px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.about-bobbi__story-chapter-two__photo-last {
  display: block;
  float: right;
  margin-left: 10px;
  margin-bottom: 10px;
  width: 58%;
}

.about-bobbi__story-chapter-three__photos {
  display: block;
  width: 35.9%;
  float: right;
  margin-left: 20px;
  margin-bottom: 20px;
}

.about-bobbi__moments-header {
  display: block;
}

.about-bobbi__moments-item__year {
  padding-top: 20px;
}

@media (max-width: 767px) {
  .product--full .product__header {
    border-bottom: none;
    margin: 0;
    padding-bottom: 0;
  }
}

@media (max-width: 767px) {
  .product--full .product__header .product__title {
    max-width: 300px;
    font-size: 14px;
    float: none;
    text-align: center;
    display: block;
    margin: 20px auto 0;
    line-height: 21px;
  }
}

@media (max-width: 767px) {
  .product-gallery__main {
    padding-left: 10px;
  }
}

.product--full .product__actions {
  padding: 30px 0 48px;
}

@media (max-width: 767px) {
  .product--full .product__button--add-to-bag {
    width: auto;
  }
}

@media (max-width: 767px) {
  .responsive-tabs--how_to_use-wrap {
    margin: 20px auto 0;
    padding: 0;
  }
}

.responsive-tabs--how_to_use-wrap {
  border-bottom: none;
}

.how-to-use__size-text {
  display: block;
  font-family: 'Brandon Text';
  text-transform: none;
}

.touch select {
  background: url('/media/images/global/icon-dropdown.png') no-repeat center right 5px;
}

.site-email-sms-signup__header {
  padding-top: 0;
}

.site-email-sms-signup {
  .site-email-sms-signup__content {
    display: block;
    margin: 0 20px;
    padding: 0;
  }
  input {
    height: 32px;
    line-height: 20px;
    padding: 5px 10px;
    width: 97%;
    margin: 0.5em 3% 0.5em 0;
  }
}

.email-singup-thanks-page {
  .email-singup-thanks-header {
    float: none;
    width: 100%;
    padding: 2em 20px;
    border-bottom: 1px solid #d0d0d0;
    text-align: center;
    h1 {
      margin-bottom: 0;
    }
  }
  .email-singup-thanks-section {
    padding: 2em 20px;
  }
}

.contact-us-page {
  .contact-us-header {
    padding: 2em 20px;
    border-bottom: 1px solid #d0d0d0;
    text-align: center;
  }
  .contact-us-header__title {
    margin: 0;
    padding: 0;
    font-family: 'Brandon Text Bold';
    font-size: 1.5em;
    letter-spacing: 5px;
    text-transform: uppercase;
  }
  .contact-us-section h2 {
    margin: 0;
    padding: 1.5em 20px;
    font-family: 'Brandon Text';
    font-size: 1em;
    font-weight: bold;
    letter-spacing: 3px;
  }
  .form-builder-container {
    padding: 1.5em 20px;
    padding-top: 0;
  }
  .contact-us-form__category {
    &.active-category {
      color: #fff;
      background-color: #ff4661;
      border: none;
    }
    margin: 1em 2%;
    padding-top: 5%;
    border: 1px solid #000;
    text-transform: uppercase;
    font-family: 'Brandon Text Bold';
    letter-spacing: 3px;
    text-align: center;
    width: 44%;
    height: 70px;
    display: block;
    float: left;
  }
  #edit-form-comments {
    width: 97%;
    margin: 0.5em 3% 0.5em 0;
    padding: 5px 10px;
  }
  .grippie {
    display: none;
  }
  input {
    height: 32px;
    line-height: 20px;
    padding: 5px 10px;
    width: 97%;
    margin: 0.5em 3% 0.5em 0;
  }
}

.contact-us-page #edit-form-category {
  clear: left;
}

.contact-us-page .required-field {
  margin-top: 30px;
  margin-bottom: 10px;
}

.contact-us-page .form-error {
  padding: 1.5em 20px;
  padding-top: 0;
}

.contact-thanks-page {
  .contact-thanks-header {
    float: none;
    width: 100%;
    padding: 2em 20px;
    border-bottom: 1px solid #d0d0d0;
    text-align: center;
    h1 {
      margin-bottom: 0;
    }
  }
  .contact-thanks-section {
    padding: 2em 20px;
  }
}

.email-unsubscribe__header {
  border-bottom: 1px solid #d0d0d0;
  text-align: center;
  padding-top: 2em;
  font-weight: bold;
  display: block;
}

.email-unsubscribe__content {
  display: block;
  margin: 0 20px;
  padding: 0;
}

.email-unsubscribe-description {
  margin-top: 10px;
}

.email-unsubscribe-page {
  .email-unsubscribe__required-note {
    margin-bottom: 5px;
    margin-top: 20px;
  }
  input {
    height: 32px;
    line-height: 20px;
    padding: 5px 10px;
    width: 97%;
    margin: 0.5em 3% 0.5em 0;
  }
}

.email-unsubscribe-thanks-page {
  .email-unsubscribe-thanks-header {
    float: none;
    width: 100%;
    padding: 2em 20px;
    border-bottom: 1px solid #d0d0d0;
    text-align: center;
    h1 {
      margin-bottom: 0;
    }
  }
  .email-unsubscribe-thanks-section {
    padding: 2em 20px;
  }
}

.about-bobbi__causes__header-img {
  float: left;
  margin-right: 15px;
  margin-bottom: 15px;
  width: 36.7%;
}

.about-bobbi__causes__title {
  margin-top: 5px;
  margin-bottom: 15px;
  padding: 0 20px;
}

.about-bobbi__causes__intro {
  padding: 0 20px;
}

.about-bobbi__causes__how-it-works {
  font-size: 16px;
  p {
    font-size: 16px;
  }
}

.about-bobbi__causes {
  padding: 50px 0 62px;
}

.store-locator--mobile .geo_search {
  display: block;
  margin-bottom: 20px;
  text-align: center;
}

.site-utils__item--stores {
  left: auto !important;
}

@media (max-width: 767px) {
  .site-email-sms-signup__header h1 {
    font-size: 1.5em;
  }
}

.homepage-hero__abstract__inner {
  width: auto !important;
}

.about-bobbi__footer__item {
  height: 300px;
}

.homepage-hero__abstract__inner--overlay {
  width: 76.875% !important;
}

.store-locator {
  .store-locator__duty-free-tab-link {
    color: #ff4661;
  }
  &.store-locator--mobile #map-canvas .store-locator__tooltip {
    width: 180px;
    height: 75px;
  }
}

@media (max-width: 767px) {
  .site-email-sms-signup__header {
    padding: 44px 0 20px;
  }
}

@media (max-width: 767px) {
  .site-email-sms-signup__header h1 {
    margin-bottom: 60px;
  }
}

.about-bobbi__moments-grid {
  padding: 0;
}

.spp-product-layout {
  .spp-product-layout__breadcrumb {
    padding: 20px;
  }
  .product-full__data-section {
    padding-bottom: 10px;
  }
  .sticky-add-to-bag__inner {
    padding: 10px 20px;
    .sticky-add-to-bag__ctas-wrapper {
      display: block;
      .sticky-add-to-bag__shade {
        width: 100%;
        padding: 0;
        margin-bottom: 6px;
        .spp-product-layout {
          &.sticky-add-to-bag__cta {
            width: 100%;
          }
        }
      }
    }
  }
  .sticky-add-to-bag__cta {
    padding-left: 0;
  }
  .sticky-add-to-bag__status {
    &.sticky-add-to-bag__status--non-shaded {
      display: none;
    }
  }
  .retailer_button {
    margin-top: 5px;
  }
}
