body.rtl {
  -webkit-transition: right 0.3s ease;
  transition: right 0.3s ease;
  right: 0;
}

body.js-nav-expanded {
  right: 260px;
  -webkit-box-shadow: inset -7px 0 10px -7px #666;
  box-shadow: inset -7px 0 10px -7px #666;
}

.rtl.js-nav-expanded .site-utils__nav {
  right: 0;
}

.rtl .site-utils__nav {
  right: -260px;
  -webkit-transition: right 0.3s ease;
  transition: right 0.3s ease;
}

.rtl.js-nav-expanded .site-utils {
  right: 260px;
}

.rtl .site-utils {
  right: 0;
  -webkit-transition: right 0.3s ease;
  transition: right 0.3s ease;
  -webkit-box-shadow: inset 7px 0 10px -7px #666;
  box-shadow: inset 7px 0 10px -7px #666;
}

.rtl .site-utils__nav ul li {
  text-align: right;
}

.rtl .site-utils__item.site-utils__item--email {
  right: auto;
  left: 5%;
}

.rtl .site-nav .menu__link--lvl-1.menu__link--has-children:after,
.rtl .site-nav .menu__link--lvl-2.menu__link--has-children:after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  left: 15px;
  right: initial;
}
/* float: right collections */
.rtl .site-footer .site-email-signup__header,
.rtl .site-footer .site-email-signup__field,
.rtl .site-footer .site-email-signup__error,
.rtl .site-footer__main .site-email-signup__field,
.rtl .site-footer__column:nth-child(2) .menu__item,
.rtl .product-thumb__image-link,
.rtl .contact-us-page .contact-us-form__category,
.rtl .coremetric-opt-out td b {
  float: right;
}

.rtl .product-thumb__abstract {
  float: left;
  padding-left: 0px;
  padding-right: 16px;
}
/* text-align: right collections */
.rtl.device-mobile .responsive-tabs h2.resp-accordion,
.rtl .site-footer__column:nth-child(2) .menu__list,
.rtl .site-footer-toggle-device,
.rtl .site-footer .bb_copyright,
.rtl .site-footer-social-links,
.rtl .country-select,
.rtl .language-select,
.rtl .coremetric-opt-out td,
.rtl.section-store-locator .makeup-lessons-item {
  text-align: right;
}

.rtl .about-bobbi__story-chapters__name,
.rtl .about-bobbi__story-chapters__number {
  text-align: right;
  padding-right: 11px;
  padding-left: auto;
}

.rtl .about-bobbi__story-chapters .icon-arrow_right {
  right: auto;
  left: 10px;
}

.rtl .contact-us-page .form-type-radios {
  clear: right;
}

.rtl .contact-us-page input[type='radio'],
.rtl .site-email-sms-signup input[type='checkbox'] {
  right: -9999px;
  left: auto;
}

.rtl .language-select li.lang_1 {
  float: right;
  border-right: none;
  margin: 0 0 0 10px;
  padding: 0 0 0 10px;
  border-left: 1px solid #ccc;
}

.rtl .coremetric-opt-out .pg_wrapper {
  padding-top: 0;
}

.rtl .responsive-tabs--store-locator .resp-tabs-list li {
  float: right;
}

.rtl .store-locator.store-locator--mobile .cart-caret {
  right: 45px;
}

.touch .rtl select {
  background-position: center left 5px;
}

.rtl .store-locator .store-locator__button {
  float: right;
  margin-right: 0;
}

.rtl .store-locator .store-locator__button:last-child {
  margin-right: 1%;
}
/* remove letter spacing */
body.rtl,
.rtl .site-nav .menu__link--lvl-1,
.rtl .site-nav .menu__link--lvl-2,
.rtl .page-header__title,
.rtl .button--outline-thin,
.rtl .site-footer__main .site-email-signup__header,
.rtl .site-email-signup__error,
.rtl .site-footer-social-links__header,
.rtl .site-footer-toggle-device,
.rtl .customer-service-header__title,
.rtl .site-email-sms-signup__header h1,
.rtl .contact-us-header__title,
.rtl .contact-thanks-header__title,
.rtl .site-utils__link,
.rtl .site-utils__promo,
.rtl .site-utils__link-text,
.rtl .contact-us-page .contact-us-section h2,
.rtl .contact-thanks-page .contact-thanks-section h2,
.rtl .desktop-text,
.rtl .product-thumb__headline,
.rtl .button--secondary,
.rtl .about-bobbi__footer__title,
.rtl .responsive-tabs .resp-tab-item,
.rtl .about-bobbi__story-bobbi__header,
.rtl .section-header,
.rtl .about-bobbi__causes__intro,
.rtl .about-bobbi__causes__intro p,
.rtl .about-bobbi__causes__how-it-works,
.rtl .about-bobbi__causes__how-it-works p,
.rtl .about-bobbi__story-chapters__number,
.rtl .store-locator--desktop .store-locator__hero h1,
.rtl .search-submit__text,
.rtl .store-locator--desktop .store-locator__locate span.text,
.rtl .store-locator--desktop .store-locator__dutyfree span.text,
.rtl .store-locator .makeup-lessons-item__title,
.rtl .email-unsubscribe-thanks-header__title,
.rtl .email-singup-thanks-header__title.rtl .homepage-hero__headline,
.rtl .homepage-hero__description,
.rtl .homepage-hero__link.link,
.rtl .homepage-hero__headline {
  letter-spacing: 0;
}

.rtl .slick-slide {
  float: right;
}

.rtl .site-utils__item--stores {
  right: 322px !important;
}

.rtl .site-utils__item.site-utils__item--email {
  right: 345px !important;
}

.rtl .site-utils__item.site-utils__item--stores {
  left: 0%;
}

.site-nav .menu__item--selected > {
  .menu__link--lvl-1.menu__link--has-children:after,
  .menu__link--lvl-2.menu__link--has-children:after {
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

.rtl .site-utils__item--hamburger {
  right: auto;
  left: 334px !important;
}

.rtl .site-email-sms-signup #edit-submit {
  letter-spacing: 0;
}

@media (max-width: 767px) {
  .js-sticky-header .js-site-header-sticky .site-utils__item--hamburger {
    left: 0px;
  }
}

.site-utils__item--hamburger {
  left: 10px !important;
}

.device-mobile .site-nav .makeuplesson .menu--lvl-2 .menu__list li a {
  font-family: 'Brandon Text';
  font-size: 14px;
  text-transform: capitalize;
  letter-spacing: 1px;
}

.rtl .site-nav .menu__link--lvl-2 {
  letter-spacing: 0 !important;
  font-family: 'Brandon Text Bold' !important;
  font-weight: normal !important;
  font-size: 12px !important;
  text-transform: uppercase !important;
}

.site-footer__main .site-email-signup__field {
  clear: both;
}

.rtl .store-locator.store-locator--mobile .search-submits {
  right: auto !important;
  left: 0 !important;
}
